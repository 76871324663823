import * as React from "react";
import Layout from "@components/layout";
import PlayStoreDownload from "@components/playstoreDownload";
import AppStoreDownload from "@components/appstoreDownload";
import Header from "@images/header.png";
import BorderlessTrnxImg from "@images/borderless-transaction.png";
import SectionHomeShot from "@images/section-home-shot.png";
import PaymentMethods from "@images/payment-methods.png";
import GradientBgImg from "@images/backgrounds/gradient.svg";
import MoneyCoinsImg from "@images/money-coins.png";
import VirtualCardImg from "@images/virtual-card.png";
import MultiCurrencyAccountImg from "@images/multi-currency-account.png";
import TechCrunchImg from "@images/blogs/tc.svg";
import TechCabalImg from "@images/blogs/tca.svg";
import CnbcImg from "@images/blogs/cnbc.svg";
import NairaMetricImg from "@images/blogs/nm.svg";
import VanguardImg from "@images/blogs/v.svg";
import YahooFinanceImg from "@images/blogs/yf.svg";

const IndexPage = () => {
	return (
		<Layout pageTitle="Platnova" headerBg="bg-purple-50">
			<section className="bg-purple-50">
				<div className="overflow-hidden pt-20">
					<div className="container px-4 mx-auto">
						<div className="flex flex-wrap -m-8">
							<div className="w-full md:w-1/2 p-8">
								<h1 className="mb-8 text-4xl lg:text-6xl font-bold font-heading md:max-w-xl leading-none">
									Money payments that works
								</h1>
								<p className="mb-11 text-md text-gray-900 font-medium md:max-w-md">
									Get the most out of your money, open multi-currency accounts.
									Seamlessly send and receive money across the globe.
								</p>
								<div className="flex gap-4 mb-10">
									<div className="w-full md:w-auto">
										<div className="block">
											<a
												href="https://apps.apple.com/us/app/platnova/id1619003446"
												target="_blank"
												rel="noreferrer">
												<AppStoreDownload />
											</a>
										</div>
									</div>
									<div className="w-full md:w-auto">
										<div className="block">
											<div className="flex flex-wrap justify-center items-center -m-1">
												<div className="w-auto p-1">
													<a
														href="https://play.google.com/store/apps/details?id=com.platnova.app"
														target="_blank"
														rel="noreferrer">
														<PlayStoreDownload />
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<h3 className="mb-3 text-gray-900 font-bold font-heading">
									Trusted by 80k+ customers
								</h3>
								<div className="flex flex-wrap items-center -m-1 mb-10">
									<div className="w-auto p-1">
										<div className="flex flex-wrap -m-0.5">
											<div className="w-auto p-0.5">
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
														fill="#7049f7"></path>
												</svg>
											</div>
											<div className="w-auto p-0.5">
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
														fill="#7049f7"></path>
												</svg>
											</div>
											<div className="w-auto p-0.5">
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
														fill="#7049f7"></path>
												</svg>
											</div>
											<div className="w-auto p-0.5">
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
														fill="#7049f7"></path>
												</svg>
											</div>
											<div className="w-auto p-0.5">
												<svg
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
														fill="#7049f7"></path>
												</svg>
											</div>
										</div>
									</div>
									<div className="w-auto p-1">
										<div className="flex flex-wrap -m-0.5">
											<div className="w-auto p-0.5">
												<p className="text-gray-900 font-bold">4/5</p>
											</div>
											<div className="w-auto p-0.5">
												<p className="text-gray-600 font-medium">
													(6k Reviews)
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="w-full lg:w-3/6 px-4">
								<img
									className="transform hover:scale-105 transition ease-in-out duration-1000 h-auto w-auto mx-auto"
									src={Header}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Section 2 */}
			<section className="relative pt-20 pb-20 lg:pb-32">
				<div className="relative container px-4 mx-auto">
					<div className="max-w-2xl mx-auto text-center mb-12 lg:mb-20">
						<h2 className="mt-8 mb-10 text-3xl lg:text-5xl font-bold font-heading">
							The only money app you would ever need.
						</h2>
						<p className="mb-10 text-xl text-gray-600">
							Stop worrying about inconsistent exchange rates, send and receive
							money from your friends and family.
						</p>
					</div>
					<div className="flex flex-wrap items-center -mx-4 -mb-8">
						<div className="grid gap-16 w-full lg:w-2/6 px-4">
							<div className="flex mb-8">
								<div>
									<h3 className="mb-4 text-2xl font-semibold font-heading">
										Easy setup
									</h3>
									<p className="text-lg text-gray-500">
										Fast and easy setup, create a free account and get started
										in 3,2,1
									</p>
								</div>
							</div>
							<div className="flex mb-8">
								<div>
									<h3 className="mb-4 text-2xl font-semibold font-heading">
										Fair and transparent rates
									</h3>
									<p className="text-lg text-gray-500">
										There's nothing to hide; it's all WYSIWYG(what you see is
										what you get), all our fees and rates are clearly displayed
										in-app
									</p>
								</div>
							</div>
						</div>
						<div className="grid mx-auto w-4/6 lg:w-2/6 px-4 mt-16 mb-16 lg:mt-0 lg:mb-0 ">
							<img
								className="mx-auto shadow-2xl rounded-2xl transform hover:scale-105 transition ease-in-out duration-1000"
								src={SectionHomeShot}
								alt=""
							/>
						</div>
						<div className="grid gap-16 w-full lg:w-2/6 px-4">
							<div className="flex mb-8">
								<div>
									<h3 className="mb-4 text-2xl font-semibold font-heading">
										Open multi-currency bank accounts
									</h3>
									<p className="text-lg text-gray-500">
										Create global accounts in USD, GBP, EUR, CAD, etc in less
										than 2 minutes
									</p>
								</div>
							</div>
							<div className="flex mb-8">
								<div>
									<h3 className="mb-4 text-2xl font-semibold font-heading">
										Send and receive payments
									</h3>
									<p className="text-lg text-gray-500">
										Send or receive money from friends and families via your
										@novatag
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="relative pt-2 lg:pt-24 lg:pb-24 bg-slate-50 overflow-hidden">
				<img className="absolute right-0 bottom-0" src={GradientBgImg} alt="" />
				<div className="relative z-10 container px-4 mx-auto">
					<div className="flex flex-wrap lg:items-center -m-8">
						<div className="w-full mt-12 md:w-1/2 p-6">
							<div className="md:max-w-2xl">
								<h4 className="mb-8 text-3xl lg:text-5xl font-semibold font-heading tracking-px-n leading-none">
									Open accounts in several currencies
								</h4>
								<p className="text-lg text-gray-900 font-medium leading-relaxed md:max-w-md">
									Manage your USD, GBP, EUR, CAD and other currency accounts in
									over 20+ African countries. Multi-currency accounts that you
									fully control.
								</p>
							</div>
						</div>
						<div className="w-auto md:w-1/2 p-8 self-end mb-10 lg:mb-20">
							<img
								className="relative lg:top-28 transform rounded-xl hover:scale-105 transition ease-in-out duration-1000"
								src={MultiCurrencyAccountImg}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			{/* Section 3 */}
			<section className="relative pt-2 lg:pt-24 pb-14 lg:pb-48 bg-slate-50 overflow-hidden">
				<img className="absolute right-0 bottom-0" src={GradientBgImg} alt="" />
				<div className="relative z-10 container px-4 mx-auto">
					<div className="flex flex-wrap lg:items-center -m-8">
						<div className="w-full md:w-1/2 p-8 self-end pb-24">
							<img
								className="relative top-28 transform hover:scale-105 transition ease-in-out duration-1000"
								src={BorderlessTrnxImg}
								alt=""
							/>
						</div>
						<div className="w-full md:w-1/2 p-6">
							<div className="md:max-w-2xl">
								<p className="mt-5 mb-7 text-sm text-purple-600 font-semibold uppercase">
									&#x1F44B; Say bye to fees
								</p>
								<h4 className="mb-12 text-3xl lg:text-5xl font-semibold font-heading tracking-px-n leading-none">
									Global payments and beyond
								</h4>
								<ul>
									<li className="mb-4 gap-2 flex items-center">
										<svg
											className="mr-1"
											width="30"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
												stroke="#4F46E5"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"></path>
										</svg>
										<p className=" font-semibold leading-normal">
											Spend and pay like a local and say goodbye to unstable
											exchange rates, unfair fees.
										</p>
									</li>
									<li className="mb-4 gap-2 flex items-center">
										<svg
											className="mr-1"
											width="30"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
												stroke="#4F46E5"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"></path>
										</svg>
										<p className=" font-semibold leading-normal">
											Sending to other customers? No worries, It's free, no
											transaction fees, no gas fee.
										</p>
									</li>
									<li className="mb-4 gap-2 flex items-center">
										<svg
											className="mr-1"
											width="30"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
												stroke="#4F46E5"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"></path>
										</svg>
										<p className=" font-semibold leading-normal">
											Fast and easy, with the best rates across all major
											currencies.
										</p>
									</li>
									<li className="mb-4 gap-2 flex items-center">
										<svg
											className="mr-1"
											width="30"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
												stroke="#4F46E5"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"></path>
										</svg>
										<p className=" font-semibold leading-normal">
											Tuition payment, rent and utility bill payments with a few
											clicks
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Section 4 */}
			<section className="relative py-32 bg-white-50 overflow-hidden">
				<div className="relative z-10 container px-4 mx-auto">
					<div className="flex flex-wrap -m-8">
						<div className="w-full md:w-3/6 p-4 px-6">
							<h2 className="mb-9 text-3xl lg:text-5xl font-bold font-heading tracking-px-n leading-none">
								Pay in your local currency
							</h2>
							<p className="mb-10 text-lg text-gray-900 font-medium leading-relaxed md:max-w-md">
								Seamlessly pay for goods and services in your local currency.
								We're connected to a wide range of banks, financial apps and
								payment providers.
							</p>

							<div className="flex flex-wrap -m-2">
								<div className="w-auto p-2">
									<img src={MoneyCoinsImg} alt="" />
								</div>
								<div className="flex-1 ">
									<p className="text-gray-600 font-medium md:max-w-sm">
										We've done the hard part, irrespective of the payment
										provider or channel, just pay with your desired payment
										method with little to no fees.
									</p>
								</div>
							</div>
						</div>
						<div className="w-full lg:w-3/6 px-4">
							<img
								className="transform hover:scale-105 transition ease-in-out duration-1000 h-auto w-auto mx-auto "
								src={PaymentMethods}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			{/* Section 5 */}
			<section className="relative pt-2 lg:pt-24 pb-14 lg:pb-48 bg-[#f5f5f7] overflow-hidden">
				<img className="absolute right-0 bottom-0" src={GradientBgImg} alt="" />
				<div className="relative z-10 container px-4 mx-auto">
					<div className="flex flex-wrap lg:items-center -m-8">
						<div className="w-full md:w-1/2 p-8 self-end mb-20 lg:mb-20">
							<img
								className="relative h-full w-auto top-28 transform hover:scale-105 transition ease-in-out duration-1000"
								src={VirtualCardImg}
								alt=""
							/>
						</div>
						<div className="w-full mt-12 md:w-1/2 p-6">
							<div className="md:max-w-2xl">
								<h4 className="mb-8 text-3xl lg:text-5xl font-semibold font-heading tracking-px-n leading-none">
									Virtual cards that you control
								</h4>
								<p className="mb-10 text-lg text-gray-900 font-medium leading-relaxed md:max-w-md">
									Shop online with Platnova cards, pay globally and spend
									effortlessly with your virtual cards in 100+ currencies with
									no fees
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Section 6 */}
			<section className="pt-24 pb-8 lg:pb-32 bg-white overflow-hidden">
				<div className="container px-4 mx-auto">
					<h2 className="mb-5 text-4xl lg:text-6xl font-bold font-heading text-center tracking-px-n leading-tight">
						As Featured In
					</h2>
					<p className="mb-20 text-lg text-gray-600 text-center font-medium leading-normal md:max-w-xl mx-auto">
						Look at that! Everyone is talking about us.
					</p>
					<div className="max-w-6xl mx-auto mb-14 lg:mb-2 pt-8">
						<div className="flex flex-wrap items-center px-6 -m-6 lg:-m-14">
							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-4 cursor-pointer">
								<img className="mx-auto h-9" src={TechCrunchImg} alt="" />
							</div>
							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-4 cursor-pointer">
								<a
									href="https://techcabal.com/2023/08/21/plantnova-redefining-the-landscape-of-global-fintech"
									target="_blank"
									rel="noreferrer">
									<img className="mx-auto h-12" src={TechCabalImg} alt="" />
								</a>
							</div>
							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-4 cursor-pointer">
								<a
									href="https://nairametrics.com/2023/08/21/platnova-redefining-the-landscape-of-global-fintech"
									target="_blank"
									rel="noreferrer">
									<img className="mx-auto h-12" src={NairaMetricImg} alt="" />
								</a>
							</div>
							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-0 cursor-pointer">
								<img className="mx-auto h-14" src={CnbcImg} alt="" />
							</div>
							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-4 cursor-pointer">
								<a
									href="https://www.vanguardngr.com/2023/08/business-possibilities-in-fintech-boundless-says-benjamin-oyemonlam"
									target="_blank"
									rel="noreferrer">
									<img className="mx-auto h-12" src={VanguardImg} alt="" />
								</a>
							</div>

							<div className="w-full sm:w-1/2 lg:w-1/6 p-6 lg:p-4 cursor-pointer">
								<img className="mx-auto h-9" src={YahooFinanceImg} alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
